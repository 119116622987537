<template>
  <main class="relative overflow-hidden">
    <button
      v-show="currentInput < 4"
      @click="$router.push({ name: 'Login' })"
      style="
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #656c7d;
      "
      class="absolute hidden md:block bg-none focus:outline-none border-none right-12 mt-7 z-50"
    >
      Already have an account? <span style="color: #ff4e00">Sign In</span>
    </button>
    <easiLayout :step="currentInput" :regType="args.registrationType">
      <div
        class="py-10 -mt-6 h-full min-h-screen relative flex items-start sm:items-center justify-center"
      >
        <!-- ///////// Select Account Type Start Here /////// -->
        <div
          v-if="display === 'account-type'"
          class="w-11/12 sm:w-10/12 md:w-10/12 flex flex-col items-center justify-center"
        >
          <easiContainer
            bg="bg-white"
            :isClose="false"
            @close="$router.go(-1)"
            class="w-full sm:py-10"
            paddingHorizontal=" "
          >
            <div class="w-full space-y-5 flex flex-col items-center">
              <!-- /////Personal Card //////-->

              <div class="flex flex-col justify-center gap-3">
                <div class="welcome-text text-primary-hover text-3xl font-bold">
                  Welcome to Eazipay
                </div>
                <div class="sub-welcome-text pb-4 mx-4">
                  Please select an account type to continue
                </div>
              </div>
              <div
                @click="selectregistrationType('PERSONAL')"
                :class="
                  args.registrationType === 'PERSONAL'
                    ? 'border-primary bg-newLimeGreen shadow-md'
                    : 'bg-white'
                "
                class="border overflow-hidden rounded-23px md:rounded-25px w-11/12 sm:w-10/12 md:w-6/12 relative cursor-pointer"
              >
                <span class="absolute top-3 right-3 cursor-pointer">
                  <img
                    v-if="args.registrationType === 'PERSONAL'"
                    src="../../assets/icons/check-active.svg"
                    alt=""
                  />
                </span>
                <div class="flex justify-between z-10">
                  <div></div>
                  <div
                    class="w-2/3 text-left z-10 absolute top-1/2 transform -translate-y-1/2 left-6"
                  >
                    <h5 class="account-head font-bold my-1 text-bodyDark">
                      Personal Account
                    </h5>
                    <p class="account-sub font-normal text-bodyDark">
                      For individuals who pay their personal and domestic staff
                    </p>
                  </div>
                  <img
                    class="w-1/3"
                    src="@/assets/img/personalCard.svg"
                    alt=""
                  />
                </div>
              </div>
              <!-- ///// Business Card //////-->
              <div
                @click="selectregistrationType('COMPANY')"
                :class="
                  args.registrationType === 'COMPANY'
                    ? 'border-primary bg-newLimeGreen shadow-md'
                    : 'bg-white'
                "
                class="border overflow-hidden rounded-23px md:rounded-25px w-11/12 sm:w-10/12 md:w-6/12 relative cursor-pointer"
              >
                <span class="absolute top-3 right-3 cursor-pointer">
                  <img
                    v-if="args.registrationType === 'COMPANY'"
                    src="../../assets/icons/check-active.svg"
                    alt=""
                  />
                </span>
                <div class="flex justify-between z-10">
                  <div></div>
                  <div
                    class="w-2/3 text-left z-10 absolute top-1/2 transform -translate-y-1/2 left-6"
                  >
                    <h5 class="account-head font-bold my-1 text-bodyDark">
                      Business Account
                    </h5>
                    <p class="account-sub font-normal text-dark-800">
                      For SMEs, startups, corporates, and large companies.
                    </p>
                  </div>
                  <img
                    class="w-1/3"
                    src="@/assets/img/businessCard.svg"
                    alt=""
                  />
                </div>
              </div>

              <!-- //// button ///// -->
            </div>
            <div
              class="cursor-pointer px-3 sm:px-12 flex justify-center md:px-20 mt-8"
            >
              <easiButton class="z-50 cursor-pointer" @click="switchView"
                ><span class="w-40 md:w-auto">Get Started</span></easiButton
              >
            </div>
            <div class="text-dark-800 mt-10 text-base font-medium text-center">
              Already have an account?
              <router-link class="text-secondary" to="/login"
                >Sign in</router-link
              >
            </div>
          </easiContainer>
        </div>

        <!-- ///////// Select Account Type End Here /////// -->

        <!-- ///////// Account Form Start Here /////// -->
        <div
          v-if="display === 'account-form'"
          class="w-10/12 flex flex-col items-center justify-center"
        >
          <easiContainer
            bg="bg-white "
            :isClose="false"
            class="w-full bg-opacity-50 px-4 sm:px-6 md:px-8 lg:px-30 h-screen-80"
            paddingHorizontal=""
            @close="
              showPasscode ? (showPasscode = false) : (display = 'account-type')
            "
          >
            <div class="w-full">
              <div class="flex flex-col gap-1">
                <h3 class="head-text">
                  {{
                    currentInput === 1
                      ? " Let’s get you started"
                      : currentInput === 2 || currentInput === 3
                      ? "Secure your Account"
                      : currentInput === 4
                      ? "Complete your profile"
                      : currentInput > 4
                      ? "Complete Your Business Profile"
                      : ""
                  }}
                </h3>

                <span class="sub-head-text text-bodyDark">{{
                  currentInput === 1
                    ? "Please fill in your details to create your account."
                    : currentInput === 2 || currentInput === 3
                    ? "Set a 6-digit passcode to protect your account."
                    : currentInput === 4
                    ? "Add a few more details to enhance your experience."
                    : currentInput > 4
                    ? "Help us tailor your experience by providing key business details"
                    : ""
                }}</span>
              </div>

              <form
                @submit.prevent=""
                ref="form"
                autocomplete="on"
                class="mt-4 md:w-1/2 md:mx-auto"
              >
                <button
                  v-show="
                    currentInput === 1 ||
                    currentInput === 2 ||
                    currentInput === 3
                  "
                  @click="
                    () => {
                      if (currentInput <= 1) {
                        display = 'account-type';
                      } else {
                        currentInput--;
                      }
                    }
                  "
                  class="bg-none focus:outline-none border-none text-left self-start"
                >
                  <i class="pi pi-arrow-left -mb-2 mr-2"></i> Back
                </button>
                <div v-if="currentInput === 1" class="w-full">
                  <div
                    class="w-full grid items-center gap-2"
                    :class="
                      args.registrationType === 'COMPANY'
                        ? 'grid-cols-1 md:grid-cols-2'
                        : 'grid-cols-1'
                    "
                  >
                    <easiTextInput
                      class="w-full"
                      placeholder="Enter your first name"
                      label="Enter your first name"
                      v-model="args.firstName"
                      :error="errorRules.firstName"
                    />
                    <easiTextInput
                      class="w-full"
                      label="Enter your last name"
                      placeholder="Enter your last name"
                      v-model="args.lastName"
                      :error="errorRules.lastName"
                    />
                  </div>

                  <easiTextInput
                    label="Enter your email address"
                    placeholder="Enter your email address"
                    v-model="args.email"
                    :error="errorRules.email"
                    :loading="submittingEmail"
                    type="email"
                  />

                  <easiTextInput
                    label="Enter your phone number"
                    placeholder="Enter your phone number"
                    v-model="args.phoneNumber"
                    :error="errorRules.phoneNumber"
                    type="number"
                  />
                  <easiTextInput
                    v-if="args.registrationType === 'COMPANY'"
                    label="Enter name of company"
                    placeholder="Enter name of company"
                    v-model="args.companyName"
                    :error="errorRules.companyName"
                  />

                  <easiTextInput
                    optional
                    showSlotButton
                    label="Please enter a referral code if you were referred (optional)."
                    placeholder="Please enter a referral code if you were referred (optional)."
                    v-model="args.referralCode"
                    :error="errorRules.referralCode"
                    buttonLabel="Submit form"
                  >
                  </easiTextInput>

                  <div
                    v-if="args.registrationType === 'COMPANY'"
                    class="w-full flex items-center gap-4 my-4"
                  >
                    <easiSelectInput2
                      class="w-full"
                      placeholder="Select business type"
                      :options="businessTypes"
                      :value="businessType"
                      :noSearch="true"
                      @update="($event) => handleBusinessType($event)"
                      :autoHeight="false"
                      :apiDashboard="true"
                    />
                    <easiSelectInput2
                      class="w-full"
                      placeholder="Select your role"
                      :options="roles"
                      :value="companyRole"
                      :noSearch="true"
                      :apiDashboard="true"
                      @update="($event) => handleBusinessRole($event)"
                    />
                  </div>

                  <div
                    class="cursor-pointer flex items-center gap-4 mt-5 text-sm"
                  >
                    <input
                      class="w-6 h-6 rounded-md"
                      type="checkbox"
                      name="checko"
                      id=""
                      :checked="indemCheck"
                      :value="false"
                      @change="indemCheck = !indemCheck"
                    />
                    <label for="checko" style="margin-top: 1px">
                      I have read and agreed to Eazipay
                      <span
                        @click="preview = !preview"
                        style="color: #2f80ed; font-weight: 400"
                        class="cursor-pointer"
                      >
                        Terms & Privacy Policy.</span
                      >
                    </label>
                  </div>

                  <div class="flex justify-center mt-8 w-full">
                    <easiButton
                      type="button"
                      :loading="loading.btnOtp"
                      @click="switchToPasscode"
                      block
                      size="small"
                      :disabled="indemCheck === false"
                      ><span class="w-44">Proceed</span></easiButton
                    >
                  </div>
                </div>

                <!--Step 2 start-->
                <div
                  v-if="currentInput === 2 || currentInput === 3"
                  class="mt-10 space-y-10"
                >
                  <div>
                    <div class="flex justify-start items-center mb-3">
                      <span> Enter Passcode </span>
                      <img
                        v-if="showPin"
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>

                    <span class="mb-4 w-fit flex justify-around items-center">
                      <easiOtp
                        class="w-full sm:w-auto"
                        :type="showPin ? 'password' : 'number'"
                        size="10"
                        :token_length="6"
                        :max-length="1"
                        @input="checkPin"
                        :error="errorRules.pin"
                      />
                    </span>
                  </div>
                  <div>
                    <div class="flex justify-start items-center mb-3">
                      <span class="capitalize text-newGray text-sm font-bold"
                        >confirm passcode
                      </span>

                      <img
                        v-if="showPin1"
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>

                    <span class="mb-4 flex justify-center items-center">
                      <easiOtp
                        class=""
                        :type="showPin1 ? 'password' : 'number'"
                        size="10"
                        :token_length="6"
                        :max-length="1"
                        @input="checkConfirmPin"
                        :error="errorRules.confirmPin"
                      />
                    </span>
                  </div>

                  <div class="flex justify-center mt-8 w-full">
                    <easiButton
                      class="z-50"
                      type="button"
                      @click="submit"
                      :loading="loading.btnGetstarted"
                      block
                      size="small"
                      ><span class="w-44">Create Passcode</span></easiButton
                    >
                  </div>
                </div>

                <!--Step 2 end-->

                <!--Step 3 start-->

                <easiModal
                  v-if="currentInput === 3"
                  :showCloseButton="false"
                  :isBorder="false"
                  :center="true"
                  rounded="4xl"
                >
                  <div style="padding: 32px">
                    <p
                      style="
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.2px;
                        text-align: center;
                      "
                      class="text-grey mb-0"
                    >
                      OTP Verification
                    </p>

                    <span
                      style="
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: #8d8e8d;
                      "
                      class="mt-3 block"
                    >
                      Please input OTP sent to your email and <br />
                      phone number
                    </span>
                  </div>
                  <span class="mb-4 flex justify-around items-center">
                    <easiOtp
                      v-model="otpCode"
                      size="10"
                      :token_length="6"
                      :max-length="1"
                      justify="center"
                      @input="checkOTP"
                      :error="errorRules.otpCode"
                    />
                  </span>
                  <div
                    class="text-bodyDark text-sm flex justify-center items-center gap-1"
                  >
                    <div
                      class="w-12 h-6 rounded-xl text-sm font-medium flex justify-center items-center bg-background"
                    >
                      {{ minutes }}
                    </div>
                    :
                    <div
                      class="w-12 h-6 rounded-xl text-sm flex font-medium justify-center items-center bg-background"
                    >
                      {{ seconds }}
                    </div>
                  </div>
                  <div
                    class="text-dark-800 text-left flex items-center justify-center"
                    style="
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 20px;
                      letter-spacing: 0.25px;
                    "
                  >
                    Did not receive OTP?
                    <easiButton
                      @click="resendOTP"
                      variant="text"
                      :loading="loading.btnOtp"
                      class="text-sm -ml-4 px-0"
                      type="button"
                      :disabled="otpHasTimedOut"
                      ><span
                        style="
                          font-weight: 700;
                          font-size: 14px;
                          line-height: 20px;
                          letter-spacing: 0.25px;
                        "
                        :class="
                          otpHasTimedOut
                            ? 'cursor-not-allowed text-lightSecondary'
                            : 'text-secondary'
                        "
                        >Resend</span
                      ></easiButton
                    >
                  </div>
                </easiModal>
                <!--Step 3 end-->
              </form>

              <!--Step 4 start-->

              <SetupComponent
                v-if="currentInput >= 4"
                @next="nextStep"
                @finish="completeSetup"
                @back="currentInput = currentInput - 1"
                :activeStep="currentInput"
                :propLoad="loading.setup"
              />
            </div>
            <!--Step 4 end-->
          </easiContainer>
        </div>

        <easiAlert
          v-if="successModal"
          @close="successModal = false"
          noIcon
          :showDefaultTop="false"
        >
          <div class="px-6">
            <div class="flex justify-center pt-6">
              <span>
                <img src="@/assets/img/newSuccess.gif" class="h-32" alt="" />
              </span>
              <!-- <img class="w-32" :src="success" alt="" /> -->
            </div>
            <!-- <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
              Congratulations Your Eazipay
              {{ accountType === "COMPANY" ? "Company" : "Individual" }} account
              has been created successfully.
            </div> -->

            <div
              style="
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: #292a29;
              "
              class="w-10/12 mx-auto text-center mt-2"
            >
              Success!
            </div>

            <div
              class="w-10/12 mx-auto text-center mt-2"
              style="
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
              "
            >
              Passcode set successfully
            </div>

            <main class="w-full mt-6 space-y-4">
              <easiButton rounded="4xl" @click="handleProceed" block
                ><span class="text-sm">Proceed to Dashboard</span>
              </easiButton>
            </main>
          </div>
        </easiAlert>
      </div>

      <easiModal
        :terms="true"
        :isBorder="false"
        rounded="2xl"
        v-show="preview"
        @close="preview = false"
      >
        <div
          class="w-full flex flex-col items-center h-auto overflow-y-scoll"
          style="overflow: auto; height: calc(100vh - 250px)"
        >
          <!-- <iframe
          src="https://eazipay.storage.googleapis.com/e25cbd6d-abe4-4caa-8b57-a43b64b3c5a3-indemnity-form-(2).pdf"
          style="width: 98%; height: 100%"
        >
        </iframe> -->
          <!-- <iframe :srcdoc="htmlContent" style="width: 98%; height: 100%">
        </iframe> -->
          <div class="w-full overflow-x-hidden">
            <htmlContent />
          </div>
        </div>
      </easiModal>
    </easiLayout>
    <div
      v-show="display === 'account-type'"
      class="absolute -bottom-16 left-0 right-0"
    >
      <img src="../../assets/img/sign-waves.png" class="h-80 w-full" alt="" />
    </div>

    <easiLoading v-if="fullScreenLoader" />
  </main>
  <!-- //////// Main form here ///////// -->
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { ref, reactive, watch, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "../../composable/helperFunctions";
import htmlContent from "@/views/main/Indemnity.vue";
import mobilebackground from "@/assets/img/signupMobile.svg";
import SetupComponent from "./SetupComponent.vue";
import { KycClasses } from "./ClassKyc";
import { DBClass } from "../../composable/IndexDB";

const { processNumber, log, validateEmail, validatePhone } = helperFunctions;
const { mutate, query, set } = useDataStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();
const showsplash = ref(true);
const checked = ref(null);
const currentInput = ref(1);
const accountType = ref(window.localStorage.getItem("registrationType"));
const minutes = ref(2);
const seconds = ref("00");
const timer = ref(120);
const disabledBtn = ref(false);
const args = reactive({
  registrationType:
    accountType.value === "COMPANY"
      ? "COMPANY"
      : accountType.value === "PERSONAL"
      ? "PERSONAL"
      : "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  companySize: "1-10",
  pin: "",
  confirmPin: "",
  referralCode: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  company: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});
const showPin = ref(true);
const showPin1 = ref(true);
const successModal = ref(false);
const indemCheck = ref(false);
const pdfSrc = ref("");
const preview = ref(false);
const isRegistered = ref(null);
const submittingEmail = ref(false);

function startTimer() {
  disabledBtn.value = true;
  const interval = setInterval(function () {
    minutes.value = parseInt(timer.value / 60, 10);
    seconds.value = parseInt(timer.value % 60, 10);

    minutes.value = minutes.value < 10 ? "0" + minutes.value : minutes.value;
    seconds.value = seconds.value < 10 ? "0" + seconds.value : seconds.value;

    if (--timer.value < 0) {
      timer.value = 120;
      disabledBtn.value = false;
      clearInterval(interval);
    }
  }, 1000);
}

const submitEmail = async () => {
  try {
    submittingEmail.value = true;
    await DBClass.DB.clearDB();
    window.localStorage.removeItem("currentScreen");
    window.localStorage.removeItem("kycProfileInput");
    window.localStorage.removeItem("businessKycInput");
    window.localStorage.removeItem("hasSetup");
    window.localStorage.removeItem("kycStatus");
    const res = await mutate({
      endpoint: "SaveRegisteringAccountEmail",
      data: {
        input: {
          email: args.email,
        },
      },
      service: "AUTH",
    });
    if (res.success) {
      // currentInput.value = 1;
      if (res.message === "Account with email exists, unto the next") {
        errorRules.email = "Account with email exists";
        return false;
      }
      return true;
    } else {
      const data = Object.values(res.data)[0];

      // console.log(data);

      if (data) {
        Object.keys(data).forEach(function (key) {
          if (data[key].length) {
            errorRules[key] = data[key][0];
            toast.error(data[key][0]);
          }
        });
      } else {
        toast.error(res.message);
      }
      return false;
    }
  } catch (e) {
    console.log(e);
  } finally {
    submittingEmail.value = false;
  }
};
const handleBusinessType = (e) => {
  businessType.value = e;
  // setTimeout(() => {
  //   currentInput.value++;
  // }, 2000);
};
const nextStep = async (event) => {
  if (args.registrationType === "COMPANY") {
    currentInput.value = currentInput.value + 1;
    window.localStorage.setItem("currentScreen", currentInput.value);
  } else {
    const kycReg = new KycClasses.PersonalKyc(
      event.bvn,
      event.idCardPhoto,
      event.idType,
      event.idValue,
      event.liveliness,
      event.nin,
      event.passportPhoto,
      event.middleName,
      event.dob
    );
    loading.setup = true;
    try {
      const res = await kycReg.submitKycForm();
      if (res.success) {
        window.localStorage.removeItem("kycProfileInput");
        router.push({
          name: "BeforeDashboard",
          query: { setupComplete: true },
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      loading.setup = false;
    }

    //have logic to hit endpoint and submit setup info
  }
};
const completeSetup = async (event) => {
  const kycReg = new KycClasses.CompanyKyc(
    event.bvn,
    event.idCardPhoto,
    event.idType,
    event.idValue,
    event.liveliness,
    event.nin,
    event.passportPhoto,
    event.middleName,
    event.dob,
    event.businessName,
    event.businessType,
    event.cacNumber,
    event.companyDocuments,
    event.location,
    event.logo
  );
  loading.setup = true;
  try {
    const res = await kycReg.submitKycForm();
    if (res.success) {
      window.localStorage.removeItem("kycProfileInput");
      window.localStorage.removeItem("businessKycInput");
      window.localStorage.removeItem("directorInput");
      router.push({
        name: "BeforeDashboard",
        query: { setupComplete: true },
      });
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.setup = false;
  }
};
const handleBusinessRole = (e) => {
  companyRoleOthers.value = false;
  companyRole.value = e;
  // setTimeout(() => {
  //   currentInput.value = 6;
  // }, 2000);
};
const businessTypes = ref([
  {
    label: "Sole Proprietorship",
    value: "SOLE_PROPIETORSHIP",
  },
  {
    label: "Limited Liability Company (LLC)",
    value: "LIMITED_LIABILITY_COMPANY",
  },
  {
    label: "Public Limited Company (PLC)",
    value: "PUBLIC_LIMITED_COMPANY",
  },
  {
    label: "Non-Governmental Organisation (NGO)",
    value: "NON_GOVERNMENTAL_ORGANISATION",
  },
  {
    label: "Government Parastatal",
    value: "GOVERNMENTAL_PARASTATAL",
  },
]);
const roles = ref([
  {
    label: "Business Owner",
    value: "Business_owner",
  },

  {
    label: "Account Officer",
    value: "Account_officer",
  },
  {
    label: "HR Personnel",
    value: "HR_personnel",
  },
]);
const businessType = ref("");
const companyRole = ref("");
const companyRoleOthers = ref(false);
const setIsRegistered = (e) => {
  isRegistered.value = e;
  setTimeout(() => {
    currentInput.value++;
  }, 2000);
};
// function previewPDF(base64Data) {
//   const byteCharacters = atob(base64Data);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512);
//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }
//   const blob = new Blob(byteArrays, { type: "application/pdf" });
//   const url = URL.createObjectURL(blob);

//   preview.value = true;
//   pdfSrc.value = url;
// }

const loading = reactive({
  btnGetstarted: false,
  btnOtp: false,
  confirm: false,
  setup: false,
});
// const loadingProgress = ref(false);

// //Query Indemnity contract when done
// async function queryIndemnityContract() {
//   loadingProgress.value = true;

//   try {
//     await query({
//       endpoint: "LoanContract",
//       payload: {
//         loanId: route.query.id,
//       },
//       service: "LOAN",
//       storeKey: "loanContract",
//     });
//     previewPDF(contract.value.base64);
//   } catch (e) {
//     console.log(e);
//   } finally {
//     loadingProgress.value = false;
//   }
// }

const otpCode = ref("");
// const display = ref(window.localStorage.getItem("display"));
const display = ref("account-type");
const showPasscode = ref(false);

const watchRoute = computed(() => route.query);
function switchView() {
  if (args.registrationType) {
    display.value = "account-form";
  }
}
watch(
  watchRoute,
  (val) => {
    // console.log(val.display, "val");
    if (val && val.display === "otp-verify") {
      display.value = "account-form";
      currentInput.value = 3;
    } else if (val && val.ref) {
      args.referralCode = val.query.ref;
    }
  },
  { immediate: true, deep: true }
);
watch(display, (val) => {
  window.localStorage.setItem("display", val);
});

watch(accountType, (val) => {
  if (val) {
    args.registrationType = val;
    window.localStorage.setItem("registrationType", val);
  }
});

onMounted(() => {
  const screen = window.localStorage.getItem("currentScreen");
  const regType = window.localStorage.getItem("registrationType");
  args.registrationType = regType;
  // accountType.value === null
  //   ? (accountType.value = "PERSONAL")
  //   : accountType.value;
  ///Init the value to the display on load to show the right form///

  if (route.query.forSetup) {
    if (route.query.forSetup === "true") {
      display.value = "account-form";
      if (screen) {
        currentInput.value = Number(screen);
      } else {
        currentInput.value = 4;
      }
    }
  }
  if (route.query.display) {
    if (route.query.display === "otp-verify") {
      display.value = "account-form";
      currentInput.value = 3;
    } else if (route.query.ref) {
      args.referralCode = route.query.ref;
    }
    //  else {
    //   display.value === null ? (display.value = "account-type") : display.value;
    // }
    // display.value = "otp-verify";
    // console.log(display.value);
  }
});

function selectregistrationType(payload) {
  if (payload) {
    accountType.value = payload;
    checked.value = true;
  } else {
    accountType.value = null;
    checked.value = null;
  }
}
// function validateAll(){

//                       if (!indemCheck) return;
//                       if (args.registrationType === 'COMPANY') {
//                         currentInput = 11;
//                       } else {
//                         currentInput++;
//                       }

//                       if (!validateEmail(args.email.trim())) {
//                         errorRules.email = 'Please enter a valid email address';
//                       } else {
//                         submitEmail();
//                       }

//                       if (args.phoneNumber.trim() === '') {
//                         errorRules.phoneNumber =
//                           'Please fill in your phone number';
//                         return false;
//                       } else if (!validatePhone(args.phoneNumber.trim())) {
//                         errorRules.phoneNumber =
//                           'Please fill in a valid phone number';
//                       } else if (
//                         isNaN(args.phoneNumber.trim()) ||
//                         processNumber(args.phoneNumber).length > 13
//                       ) {
//                         errorRules.phoneNumber =
//                           'Please fill in a valid phone number';
//                         return false;
//                       } else {
//                         if (args.registrationType === 'COMPANY') {
//                           currentInput++;
//                         } else {
//                           currentInput += 2;
//                         }
//                       }

// }
function validateForm() {
  const number = processNumber(args.phoneNumber);

  if (args.firstName.trim() === "") {
    errorRules.firstName = "Please fill in your first name";
    return false;
  } else if (args.lastName.trim() === "") {
    errorRules.lastName = "Please fill in your last name";
    return false;
  } else if (args.email.trim() === "") {
    errorRules.email = "Please fill in your email address";
    return false;
  } else if (!validateEmail(args.email.trim())) {
    errorRules.email = "Please enter a valid email address";
  } else if (args.phoneNumber.trim() === "") {
    errorRules.phoneNumber = "Please fill in your phone number";
    return false;
  } else if (!validatePhone(args.phoneNumber.trim())) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
  } else if (
    isNaN(args.phoneNumber.trim()) ||
    processNumber(args.phoneNumber).length > 13
  ) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
    return false;
  } else if (
    args.companyName.trim() === "" &&
    args.registrationType === "COMPANY"
  ) {
    errorRules.company = "Please fill in your company";
    return false;
  }
  // else if (args.pin.trim() === "") {
  //   errorRules.pin = "Please set a pin";
  //   return false;
  // }
  // else if (args.confirmPin.trim() === "") {
  //   errorRules.confirmPin = "Please confirm your pin";
  //   return false;
  // } else if (args.pin !== args.confirmPin) {
  //   errorRules.pin = "Pin does not match. Please check and put a matching pin";
  //   return false;
  // }
  else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}

async function logRegistration(arg, title) {
  try {
    await mutate({
      endpoint: "LogRegister",
      data: { input: arg, title },
      service: "AUTH",
    });
  } catch (e) {
    console.log(e);
  }
}
const logPayload = ref({});
function sixChar(inp) {
  return inp.slice(0, 6);
}
async function submit() {
  // console.log("result", validateForm());
  if (validateForm()) {
    window.localStorage.setItem("userDetails", JSON.stringify(args));
    window.localStorage.setItem(
      "businessDetails",
      JSON.stringify({
        businessType: businessType.value,
        isRegistered: isRegistered.value,
        companyName: args.companyName,
      })
    );
    // const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    const payload = {
      registrationType: args.registrationType,
      lastName: args.lastName,
      firstName: args.firstName,
      email: args.email.trim().toLocaleLowerCase(),
      phoneNumber: processNumber(args.phoneNumber),
      companyName: args.companyName,
      companySize: args.companySize,
      pin: sixChar(String(args.pin)),
      confirmPin: args.confirmPin,
      referralCode: args.referralCode ? args.referralCode : null,
      businnesType: businessType.value,
      adminCompanyRole: companyRole.value,

      isRegistered: isRegistered.value,
    };
    if (!companyRole.value) delete payload.adminCompanyRole;
    if (!businessType.value) delete payload.businnesType;
    if (!isRegistered.value) delete payload.isRegistered;
    // console.log("payload", {
    //   ...payload,
    //   isRegistered: isRegistered.value,
    //   businessType: businessType.value,
    //   companyRole: companyRole.value,
    // });
    logPayload.value = payload;

    try {
      loading.btnGetstarted = true;
      let res = await mutate({
        endpoint: "LiteRegisterCompany",
        data: { input: payload },
        service: "AUTH",
      });

      log(res);
      if (res.success === true) {
        // successModal.value = true;
        // toast.success("Signup successful, Please check email for otp code");

        await sendOTP();

        // display.value = "otp-verify";
        loading.btnGetstarted = false;
      } else {
        loading.btnGetstarted = false;
        const data = Object.values(res.data)[0];

        console.log(data);

        if (data) {
          Object.keys(data).forEach(function (key) {
            if (data[key].length) {
              errorRules[key] = data[key][0];
              toast.error(data[key][0]);
            }
          });
        } else {
          toast.error(res.message);
        }
        await logRegistration(payload, "Attempted Registration");
      }
    } catch (e) {
      loading.btnGetstarted = false;
      toast.error(e.message);
      await logRegistration(payload, "Attempted Registration");
      console.log(e);
    }
  } else {
  }
}
const otpTimer = ref(null);
const otpHasTimedOut = ref(true);
function startOTPTimer() {
  // Clear the existing timer, if any
  clearTimeout(otpTimer.value);
  otpHasTimedOut.value = true;

  // Set the timer for 2-3 minutes (in milliseconds)
  otpTimer.value = setTimeout(async function () {
    console.log("OTP not entered within the time limit.");
    otpHasTimedOut.value = false;
    await logRegistration(logPayload.value, "OTP not inputted after 2 minutes");
    // Call your function here to handle the case when OTP isn't entered
    // For example, you can show an error message or take some other action.
  }, 2 * 60 * 1000); // 2 minutes (change to 3 minutes by replacing 2 with 3)
}

async function sendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

  if (validateForm()) {
    loading.btnOtp = true;

    //validate form field

    const payload = {
      channel: "EMAIL",
      // token_length: 4,
      // value: userDetails.email,
      value: args.email,
      type: "SIGNUP",
      // phoneNumber: processNumber(userDetails.phoneNumber),
      // firstName: userDetails.firstName,
    };

    try {
      let res = await mutate({
        endpoint: "SendOTP",
        data: { input: payload },
        service: "AUTH",
      });
      if (res.success) {
        //Store form details and reference in localstorage
        // window.localStorage.setItem("userDetails", JSON.stringify(args));
        window.localStorage.setItem("otpReference", res.data.reference);
        currentInput.value = 3;
        startTimer();
        //send user to OTP form
        startOTPTimer();
      } else {
      }
    } catch (e) {
      console.log(e);
    } finally {
      loading.btnOtp = false;
    }
  }
}
async function switchToPasscode() {
  loading.btnOtp = true;
  try {
    if (validateForm()) {
      showPasscode.value = true;

      const bool = await submitEmail();
      if (bool) {
        // await sendOTP();
        currentInput.value = 2;
      }

      // currentInput.value = currentInput.value + 1;
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.btnOtp = false;
  }
}
async function confirmOtp() {
  const admin = args.email;

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    loading.confirm = true;

    const payload = {
      reference: window.localStorage.getItem("otpReference"),
      code: otpCode.value,
      value: admin,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;
        //Register user
        await submit();
        // currentInput.value = 3;

        // loading.confirm = false;
        // enterOtp.value = false;
      } else {
        errorRules.otpCode = res.message;
      }
    } catch (e) {
      // loading.confirm = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      loading.confirm = false;
    }
  }
}
const fullScreenLoader = ref(false);
async function confirmRegistration() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    clearTimeout(otpTimer.value);
    loading.confirm = true;
    // console.log(otpCode.value);

    let payload;
    if (route.query.display) {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: confirmUserDetails.value,
      };
    } else {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: userDetails.email,
      };
    }

    fullScreenLoader.value = true;

    try {
      let res = await mutate({
        endpoint: "ConfirmRegistration",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        // Set form back to register
        // display.value = "account-type";
        //Clear error state
        await queryCompany();
        errorRules.otpCode = false;
        loading.confirm = false;
        successModal.value = true;

        window.localStorage.setItem("token", res.token);
        window.localStorage.setItem("refreshToken", res.refreshToken);
        window.localStorage.setItem("privilege", res.privileges);
        window.localStorage.setItem("companyId", res.data.company._id);

        //Turn on Tour
        localStorage.setItem("tour", "true");

        useDataStore().$patch({
          userPrivileges: res.privileges,
          isLoggedIn: true,
        });
        // setTimeout(async () => {
        //   successModal.value = false;
        //   // toast.success(res.message);

        //   window.localStorage.setItem("token", res.token);
        //   window.localStorage.setItem("refreshToken", res.refreshToken);
        //   window.localStorage.setItem("privilege", res.privileges);
        //   window.localStorage.setItem("companyId", res.data.company._id);

        //   //Turn on Tour
        //   localStorage.setItem("tour", "true");

        //   useDataStore().$patch({
        //     userPrivileges: res.privileges,
        //     isLoggedIn: true,
        //   });
        //   // display.value = "account-type";
        //   // router.push({ name: "AdminDashboard", query: { from: "signup" } });
        //   await queryCompany();
        // }, 4000);
      } else {
        errorRules.otpCode = res.message;
        toast.error(res.message);
        loading.confirm = false;
      }
    } catch (e) {
      log(e);
      loading.confirm = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      fullScreenLoader.value = false;
    }
  }
}
async function handleProceed() {
  successModal.value = false;
  // toast.success(res.message);

  // display.value = "account-type";
  router.push({ name: "AdminDashboard", query: { from: "signup" } });
}
async function resendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  // log(args);
  // log("user details", userDetails);

  let payload;

  if (route.query.display) {
    payload = {
      channel: confirmUserDetails.channel,
      value: confirmUserDetails.value,
      type: "SIGNUP",
    };
  } else {
    payload = {
      channel: "EMAIL",
      value: userDetails.email,
      type: "SIGNUP",
    };
  }

  try {
    loading.btnOtp = true;
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      loading.btnOtp = false;
      startTimer();
      startOTPTimer();
      toast.success("OTP sent successful. Please check email");
      window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    loading.btnOtp = false;
  }
}

//Update OTP Input Functions
function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

let ranOnce = false;

async function checkOTP(otp_input) {
  otpCode.value = otp_input;
  if (otpCode.value.length === 6 && ranOnce === false) {
    ranOnce = true;
    // await confirmOtp();
    await confirmRegistration();
  }
}

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
}
</script>

<style scoped>
.head-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #292a29;
}

.sub-head-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
[type="checkbox"]:checked {
  border-color: transparent;
  border: 2px solid #11453b;
  background-color: white;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.account-head {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.account-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.box-pad {
  padding: 24px;
}
.welcome-text {
  font-size: 36px;
  line-height: 48px;
  text-align: center;
}
@media (max-width: 768px) {
  .welcome-text {
    font-size: 24px;
    line-height: 32px;
  }
  .sub-welcome-text {
    font-size: 16px;
    line-height: 24px;
  }
  .head-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .sub-head-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
</style>
